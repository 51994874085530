const globalConstants = {
	dateFormat: 'MMMM DD, YYYY hh:mm A',
};

export const involvedEmployeeFields = [
	'Number of Employees Involved in Incident',
];
export const witnessFields = ['Number of Witness Involved in Incident'];

const appSettings = {
	chatSupport: false,
};
/**
 * @type {ConfigRoutes} Base API routes
 */

const base = {
	baseUrl: 'http://localhost:3000',
	apiURL: 'http://localhost:3007/api/v1',
	portalUrl: 'https://portal-staging.maximumaccountability.net',
	userRepoUrl: 'https://staging.users.api.maximumaccountability.net/api/v1',
	tokenKey: 'ma-eic-token',
	impersonateToken: 'eic-obo-token',
	portalTokenKey: 'stagingAuthToken',
	addCustomFormData: '/tickets/add/customform/data',
	getTickets: '/tickets',
	getMultiTickets: '/tickets/get/many',
	saveTicket: '/tickets/request',
	getAllUsers: '/admin/listAllUsers',
	getUsers: '/admin/listUsers',
	getUsersWithLookup: '/admin/listUsersWitLookup',
	getTicket: '/tickets/get',
	getTuple: '/tickets/getTuple',
	getGroups: '/tickets/getGroups',
	listGroups: '/tickets/listGroups',
	saveMessage: '/tickets/messages/save',
	getAttachment: '/tickets/attachments/get',
	deleteTickets: '/tickets/delete',
	mergeTickets: '/tickets/merge',
	assignTicket: '/tickets/assign',
	bulkReply: '/tickets/bulkReply',
	updateTicket: '/tickets/save',
	updateTicketStatus: '/tickets/toggle-status',
	adminUsers: '/admin/listUsers',
	superAdminUsers: '/admin/listAllUsers',
	adminBatchGetUsers: '/admin/user/batch',
	profileImage: '/user/profileImage',
	getMessages: '/tickets/messages/summary',
	getSettings: '/user/settings',
	googleClientId:
		'122821283770-ul448fqbj0tsp9fv7dl69qd5om15t6j6.apps.googleusercontent.com',
	agentSignUp: '/admin/sign-up',
	agentDelete: '/user/delete/',
	updateAgent: '/admin/update-user',
	getAssets: '/user/assets',
	createAccount: '/user/sign-up',
	activateAccount: '/user/activate',
	getVisibleViews: '/views/visible/all',
	allViews: '/views/all',
	toggleView: '/views/toggle-visbility',
	getView: '/views',
	updateView: '/views/update',
	createView: '/views/create',
	fieldsView: '/views/fields',
	deleteView: '/views/delete',
	allFields: '/fields/all',
	field: '/fields/get/',
	batchField: '/fields/get/batch',
	saveField: '/fields/save',
	deleteField: '/fields/delete/',
	allDefaultFields: '/fields/allDefault',
	allAutomations: '/admin/automations/all',
	automations: '/admin/automations',
	automationCreateRule: '/admin/automations/upsert',
	automationsDeleteRule: '/admin/automations/delete/',
	automationsToggleRule: '/admin/automations/toggle',
	automationsUpdateOrder: '/admin/automations/update-order',
	getTicketSettings: '/admin/ticket-settings',
	updateTicketSettings: '/admin/ticket-settings/update',
	customFormBase: '/admin/custom_form',
	oshaFormFields: '/osha-form-fields',
	listEnterprises: '/admin/list-enterprises',
	listEnterprisesV2: '/admin/list-enterprises/v2',
	updateEnterprise: '/admin/update-enterprise',
	addEnterprise: '/admin/new-enterprise',
	getEnterprise: '/enterprise/',
	createField: '/fields/create/',
	createFieldBulk: '/fields/save-fields',
	updateFieldBulk: '/fields/update-fields',
	getAutomationFields: '/fields/all',
	getDefaultAutomationFields: '/fields/default/',
	getAllMailboxes: '/admin/mailbox/all',
	getPrimaryMailbox: '/admin/mailbox/primary',
	getSiteSettings: '/utils/site-settings',
	getRoles: '/agent/roles',
	getTags: '/admin/tags',
	contactCreate: '/admin/contact/create',
	contactProfile: '/admin/contact/profile/',
	contactupdate: '/admin/contact/update',
	contactDelete: '/admin/contact/delete/',
	updateContactStatus: '/admin/contact/change-status/',
	agentCreate: '/admin/create-user',
	getTicketHistory: '/tickets/history',
	getSignInOptions: '/admin/enterprise/signin-options',
	getSSODetails: '/admin/sso/get-profiles',
	updateSSODetails: '/admin/sso/update-details',
	deleteSSO: '/admin/sso/delete',
	SSOLogin: '/admin/sso/login',
	checkProfile: '/user/check-profile',
	getUserNotifications: '/user/notifications',
	updateUserNotifications: '/user/notifications/update',
	samlRedirectUrl: '/auth/saml-callback?id=',
	ssoRedirectUrl: '/sso-callback?id=',
	ssoVerifyUrl: '/auth/sso-callback',
	checkLogin: '/auth/check-login',
	getSignupConfig: '/admin/enterprise/get-signup-config',
	updateSignupConfig: '/admin/enterprise/update-signup-config',
	impersonate: '/admin/impersonate',
	getArticle: '/admin/article/id',
	getIncidents: '/incidents',
	getIncident: '/incidents/get/',
	saveIncident: '/incidents/request',
	updateIncident: '/incidents/save',
	deleteIncident: '/incidents/delete',
	getIncidentMessages: '/incidents/case-discussions',
	saveIncidentMessage: '/incidents/case-discussion/request',
	getIncidentTasks: '/tasks',
	saveIncidentTask: '/tasks/request',
	updateIncidentTask: '/tasks/update/',
	getIncidentNotes: '/incidents/case-notes',
	saveIncidentNotes: '/incidents/case-notes/request',
	updateIncidentNotes: '/incidents/case-notes/update/',
	getIncidentAttachments: '/incidents/attachments/get?incidentId=',
	saveIncidentAttachment: '/incidents/attachments/create',
	updateIncidentAttachment: '/incidents/attachments/update',
	getIncidentParticipants: '/incidents/case-participants/list',
	saveIncidentParticipant: '/incidents/case-participants/create',
	updateIncidentParticipant: '/incidents/case-participants/update',
	deleteIncidentParticipant: '/incidents/case-participants/delete/',
	getIncidentSynopsis: '/incidents/history/synopsis',
	updatePassword: '/user/updatePassword',
	passwordReset: '/user/passwordResetLink',
	getOshaAdditionalData: '/incidents-report/get-osha-additional-data',
	generateOsha301Report: '/incidents-report/generate-osha-301-report',
	generateOsha300AReport: '/incidents-report/generate-osha-300a-report',
	generateOsha300Report: '/incidents-report/generate-osha-300-report',
	resendSecondaryInvite: '/user/resend-secondaryemail-link',
	activateSecondaryEmail: '/user/activate-se',
	fetchFaqCategories: '/admin/category',
	fetchUserFaqCategories: '/category',
	fetchAgentFaqCategories: '/admin/agent-category',
	fetchFaqSubCategoriesNArticle: '/admin/sub-category/articles',
	fetchUserFaqSubCategoriesNArticle: '/sub-category/articles',
	fetchAgentFaqSubCategoriesNArticle: '/admin/agent-sub-category/articles',
	approveArticle: '/admin/change-request/approve/',
	rejectArticle: '/admin/change-request/reject/',
	articleSearch: '/admin/article/search',
	userArticleSearch: '/article/search',
	agentArticleSearch: '/admin/agent-article/search',
	checkUserExists: '/user/check-user-exist',
	getApplicationSettings: '/check-maintenance',
	ticketsCount: '/tickets/count',
	incidentsCount: '/incidents/count',
	faqChangeRequestCount: '/admin/article/change-request-count',
	fetchFaqChangeRequests: '/admin/article/change-request',
	fetchChangeRequestData: '/admin/change-request/',
	fetchPrevChat: '/user-chats/chat-list',
	fetchAllSatisfactionSurveys: '/admin/surveys-form',
	createSatisfactionSurvey: '/admin/surveys-form/create',
	updateSatisfactionSurvey: '/admin/surveys-form/update',
	fetchSatisfactionSurvey: '/admin/surveys-form/',
	deleteSatisfactionSurvey: '/admin/surveys-form/delete/',
	updateSatisfactionSurveyStatus: '/admin/surveys-form/update-status',
	submitSatisfactionSurvey: '/surveys/create',
	checkSurveySubmitted: '/surveys/check-feedback-submitted',
	getWorkflowInfo: '/workflows/info/',
	getWorkflowExecutions: '/workflows/executions/',
	getWorkflowExecutionLog: '/workflows/execution-log/',
	executeWorkflow: '/workflows/execute/',
	getAllWorkflows: '/workflows/all/',
	saveWorkflow: '/workflows/upsert/',
	getSupervisorData: '/org-tree/supervisor/',
	ticketsPaginationKey: 'QS_TICKETS_PAGINATION',
	incidentsPaginationKey: 'QS_INCIDENTS_PAGINATION',
	automationsPaginationKey: 'QS_AUTOMATIONS_PAGINATION',
	selectedEnterpriseKey: 'QS_ENTERPRISE',
	employeeDefaultFields: [...involvedEmployeeFields, ...witnessFields],
	appSettings : appSettings
};

/**
 * @type {ConfigRoutes} Development API routes
 */
const dev = {
	isLocalEnv: true,
};

/**
 * @type {ConfigRoutes} Staging API routes
 */
const staging = {
	baseUrl: 'https://staging.maximumaccountability.net',
	apiURL: 'https://staging.api.maximumaccountability.net/api/v1',
	portalUrl: 'https://portal-staging.maximumaccountability.net',
	portalTokenKey: 'stagingAuthToken',
};

/**
 * @type {ConfigRoutes} Production API routes
 */
const prod = {
	baseUrl: 'https://eic.maximumaccountability.net',
	apiURL: 'https://eic.api.maximumaccountability.net/api/v1',
	portalUrl: 'https://portal.maximumaccountability.net',
	userRepoUrl: 'https://users.api.maximumaccountability.net/api/v1',
	portalTokenKey: 'prodMaAuthToken',
};

/**
 * The type of configuration routes to apply, runs off of the process's application environment
 */
const config =
	process.env.REACT_APP_ENV === 'production'
		? prod
		: process.env.REACT_APP_ENV === 'staging'
			? staging
			: dev;

/**
 * Destructured config object
 * FIXME Only does a shallow merge, wont work with complex objects
 */
const exported_config = {
	...base,
	...config,
	...{ globalConstants },
};

export default exported_config;
